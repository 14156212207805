// 국가 리스트
export const network = {
  // 국가별 정보
  ctrList: [
    { seq: '01', cdNm: 'KOREA' },
    { seq: '02', cdNm: 'JAPAN' },
    { seq: '03', cdNm: 'CHINA' },
    { seq: '04', cdNm: 'HONGKONG' },
    { seq: '05', cdNm: 'PHILIPPINES' },
    { seq: '06', cdNm: 'SINGAPORE' },
    { seq: '07', cdNm: 'MALAYSIA' },
    { seq: '08', cdNm: 'INDONESIA' },
    { seq: '09', cdNm: 'THAILAND' },
    { seq: '10', cdNm: 'VIETNAM' },
    { seq: '11', cdNm: 'BANGLADESH' },
    { seq: '12', cdNm: 'INDIA' },
    { seq: '13', cdNm: 'CAMBODIA' },
    { seq: '14', cdNm: 'RUSSIA' },
    { seq: '15', cdNm: 'SRILANKA' },
    { seq: '16', cdNm: 'U.A.E' },
    { seq: '17', cdNm: 'MYANMAR' },
    { seq: '18', cdNm: 'PAKISTAN' },
    { seq: '19', cdNm: 'SAUDI ARABIA' },
    { seq: '20', cdNm: 'OMAN' },
    { seq: '21', cdNm: 'KENYA' },
    { seq: '22', cdNm: 'IRAQ' },
    { seq: '23', cdNm: 'KUWAIT' },
    { seq: '24', cdNm: 'TANZANIA' },
    { seq: '25', cdNm: 'EGYPT' },
    { seq: '26', cdNm: 'JORDAN' }
  ],
  ctrInfoList: [
    {
      key: '01',
      keyNm: 'KR',
      info: [
          { area: 'SEOUL', cnt: '6', team: 'Customer Service Team', job: 'Customer Service (Export) & issue', officeTel: '82-2-311-6114', email: 'csc@ekmtc.com' },
          { team: 'Overseas Business Team', job: 'Overseas Sales', officeTel: '82-2-311-6185', email: 'obt@ekmtc.com' },
          { team: 'Export Sales Team', job: 'Export Sales', officeTel: '82-2-311-6198/6162', email: 'est@ekmtc.com' },
          { team: 'Import Sales Team', job: 'Import Sales', officeTel: '82-2-311-6157', email: 'ist@ekmtc.com' },
          { team: 'Japan Business Team', job: 'Japan Sales', officeTel: '82-2-311-6375', email: 'jbt@ekmtc.com' },
          { address: 'KOREA MARINE TRANSPORT CO.,LTD.\n\nADDRESS: 15TH FL., HANJIN BLDG, 63 NAMDAEMUN-RO, JUNG-GU, SEOUL, KOREA (POST CODE: 04532)', rows: '3' },
          { area: 'BUSAN', cnt: '2', team: 'Sales Team', job: 'Sales', officeTel: '82-51-461-2800/2900', email: 'pussa@ekmtc.com' },
          { address: 'KOREA MARINE TRANSPORT CO.,LTD. PUSAN BRANCH\n\nADDRESS: 10TH FL., KYOBO LIFE INSURANCE BLDG, 88-77, 4-KA, CHUNGANG-DONG, CHUNG-KU, PUSAN, KOREA (POST CODE: 48939)', rows: '3' },
          { area: 'INCHEON', cnt: '2', team: 'Incheon Office', job: 'Overall', officeTel: '82-32-883-2800', email: 'inc@ekmtc.com' },
          { address: 'KOREA MARINE TRANSPORT CO.,LTD. INCHEON OFFICE\n\n8TH FLOOR, JUNGSUK NEW BLDG., 366 SEOHAE-DAERO, JUNG-GU, INCHEON, KOREA (POST CODE : 22332)', rows: '3' },
          { area: 'ULSAN', cnt: '2', team: 'Ulsan Office', job: 'Overall', officeTel: '82-52-261-0234', email: 'usn@ekmtc.com' },
          { address: 'KOREA MARINE TRANSPORT CO.,LTD. ULSAN OFFICE\n\n4TH FL., UNCT 1, SINHANG-RO 716 BEON-GIL, NAM-GU, ULSAN, KOREA (POST CODE: 44784)', rows: '3' },
          { area: 'BUSAN(Docu Cnter)', cnt: '6', team: 'Export S/R Team', job: 'Export Documentation (S/R)', officeTel: '82-51-797-7400 line 1', email: 'docsr@ekmtc.com' },
          { team: 'Export C/A Team', job: 'Export Documentation (C/A)', officeTel: '82-51-797-7400 line 2', email: 'docca@ekmtc.com' },
          { team: 'Export EDI Team', job: 'DG Certi, Export Custom', officeTel: '82-51-797-7400 line 3', email: 'docedi@ekmtc.com' },
          { team: 'Export CLL Team', job: 'Export Closing management', officeTel: '82-51-797-7400 line 4', email: 'doccll@ekmtc.com' },
          { team: 'Import Team', job: 'Import documentation', officeTel: '82-51-797-7400 line 5', email: 'docid@ekmtc.com' },
          { team: 'IssueTeam', job: 'Surrendered, Sea Waybill Issue, D/O issue, Tax bill', officeTel: '82-51-797-7400 line 6', email: 'docis@ekmtc.com' }
        ]
    },
    {
      key: '02',
      keyNm: 'JP',
      info: [
        { area: 'TOKYO', cnt: '4', team: 'Sales Team', job: 'Outbound Sales,Inbound Sales', officeTel: '81-3-3500-5055', email: 'tyosa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '81-3-3500-5051', email: 'tyocs@ekmtc.com' },
        { team: 'Document Team', job: 'Export & Import Documentation', officeTel: '81-3-3500-5180', email: 'tyodoc@ekmtc.com' },
        { address: 'KMTC (JAPAN) CO.,LTD. (TOKYO BRANCH)\n\nADDRESS : 4TH FLOOR, NIHONSEIMEI SHINBASHI,BLDG NO.18-16, 1-CHOME, SHINBASHI, MINATO-KU, TOKYO, JAPAN (POSTAL CODE : 105-0004)', rows: '3' },

        { area: 'OSAKA', cnt: '3', team: 'Sales Team', job: 'Outbound Sales,Inbound Sales', officeTel: '81-6-6243-1661', email: 'osasa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '81-6-6243-1661', email: 'osacs@ekmtc.com' },
        { address: 'KMTC (JAPAN) CO.,LTD. (OSAKA OFFICE)\n\nADDRESS: 2ND FLOOR, RE-012 BLDG., NO.4-17, 4-CHOME, HONMACHI CHUO-KU, OSAKA, JAPAN (POSTAL CODE :  541-0053)', rows: '3' },

        { area: 'AKITA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-18-880-6770', email: 'axt.ope@kairiku.co.jp' },
        { address: 'AKITA KAIRIKU CO., LTD.\n\nADDRESS : 5-9, NISHI 2-CHOME, TSUCHIZAKI-MINATO,AKITA,JAPAN (POSTAL CODE : 011-0951)', rows: '3' },

        { area: 'CHIBA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-43-238-7311', email: 'nittsu-nexchb@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD.\n\nADDRESS: 1-174 CHUOUKOU, CHOU-KU,CHIBA CITY, CHIBA JAPAN (POSTAL CODE : 260-0024)', rows: '3' },

        { area: 'FUKUYAMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-84-981-0205', email: 'marine_sect@bintsu.co.jp' },
        { address: 'BINGO EXPRESS CO.,LTD.FUKUYAMA BRANCH\n\nADDRESS: 484-25 MINOSHIMA-MACHI, FUKUYAMA-CITY, HIROSHIMA,JAPAN (POSTAL CODE : 721-0957)', rows: '3' },

        { area: 'HACHINOHE', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-178-21-6000', email: 'hachi@narasaki-stax.co.jp' },
        { address: 'NARASAKI STAX CO.,LTD. HACHINOHE BRANCH\n\nADDRESS : 10-4 KAIGAN, KAWARAGI,HACHINOHE-CITY,AOMORI, JAPAN (POSTAL CODE: 039-1161)', rows: '3' },

        { area: 'HIROSHIMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-82-253-2111', email: 'agent@hirokura.co.jp' },
        { address: 'HIROKURA CO.,LTD.\n\nADDRESS: 3-9-13, UJINA-KAIGAN, MINAMI-KU, HIROSHIMA-CITY, HIROSHIMA ,JAPAN (POSTAL CODE : 734-0011)', rows: '3' },

        { area: 'HAKATA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-92-663-3511', email: 'nittsu-hkt-agent@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. FUKUOKA SHIPPING BRANCH\n\nADDRESS: 12-10, SEKIJYO-MACHI, HAKATA-KU,FUKUOKA-CITY,FUKUOKA,JAPAN (POSTAL CODE :812-0032)', rows: '3' },

        { area: 'HOSOSHIMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-982-56-1612', email: 'nittsu-hsm_agent@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. NOBEOKA BRANCH /INTERNATIONAL TRANSPORT DEPT.\n\nADDRESS: 3,TAKESHIMA HYUGA-CITY,MIYAZAKI-PREF,JAPAN (POSTAL CODE :  883-0063)', rows: '3' },

        { area: 'HITACHINAKA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-29-265-5539', email: 'sales@hitachi-futo.co.jp' },
        { address: 'HITACHI FUTO CO.,LTD\n\nADDRESS: 768-46,NAGISA,TERUNUMA TOKAI-VIL,NAKAGUN,IBARAKIPREF.,JAPAN (POSTAL CODE : 319-1113 )', rows: '3' },

        { area: 'IMABARI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-898-48-6900', email: 'nittsu-imabari@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. IMABARI BRANCH\n\nADDRESS: 1-1-5, TOMITA SHINKO, IMABARI, EHIME, JAPAN (POSTAL CODE : 799-1503)', rows: '3' },

        { area: 'IMARI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-955-20-7110', email: 'imari-agsec@nagaij.co.jp' },
        { address: 'NAGAI CO.,LTD. & IMARI HARBOUR TRANSPORT COMPANY (IMI)\n\nADDRESS: 5-22 SHIOYA KUROGAWA, IMARI, SAGA, OSAKA, JAPAN (POSTAL CODE : JAPAN 848-0121)', rows: '3' },

        { area: 'ISHIKARI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-134-61-6151', email: 'ishiagency@narasaki-stax.co.jp' },
        { address: 'NARASAKI STAX CO., LTD. ISHIKARI OFFICE (ISI)\n\nADDRESS: SHINKO BUILDING 2F, 202-1, 1-CHOME , SHINKO-CHUO , ISHIKARI-CITY, HOKKAIDO, JAPAN (POSTAL CODE :  061-3242)', rows: '3' },

        { area: 'IWAKUNI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-827-52-4311', email: 'nittsu-otake_iwakuni_agent@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO., LTD. OHTAKE BRANCH\n\nADDRESS: 3-38-1, HIGASHISAKAE, OTAKE-CITY, HIROSHIMA PREF, JAPAN (POSTAL CODE : 739-0601)', rows: '3' },

        { area: 'IYOMISHIMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-896-24-9220', email: 'agency@daio-kaiun.com' },
        { address: 'DAIO KAIUN CO.,LTD. / IYOMISHIMA AGENT\n\nADDRESS : 7-35, MISHIMAKAMIYAMACHI, SHIKOKUTYUO CITY, EHIME PREF., JAPAN (POSTAL CODE : 799-0402)', rows: '3' },

        { area: 'NIIGATA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Documentation(Import/Export)', officeTel: '81-25-256-1177', email: 'nittsu-niigata-150065@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD.NIIGATA SHIPPING BRANCH (KIJ)\n\nADDRESS: 3228-2, KITA-KU YOKODOI, NIIGATA-CITY, NIIGATA , JAPAN (POSTAL CODE : 950-3303)', rows: '3' },

        { area: 'KUMAMOTO', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Documentation(Import/Export)', officeTel: '81-96-223-5497', email: 't-fujita@misumikaiun.co.jp' },
        { address: 'MISUMI KAIUN CO., LTD (KMJ)\n\nADDRESS: 4-26, MINAMITSUBOI-CHO, KUMAMOTO-CITY,KUMAMOTO, JAPAN (POSTAL CODE : 860-0848)', rows: '3' },

        { area: 'KANAZAWA', cnt: '3', team: 'SHIPPING AGENCY TEAM', job: 'Inbound Documentation', officeTel: '81-76-256-0543', email: 'doc_imp@kounknz.co.jp' },
        { team: 'SHIPPING AGENCY TEAM', job: 'Outbound Documentaion', officeTel: '81-76-256-0543', email: 'doc_exp@kounknz.co.jp' },
        { address: 'KANAZAWA KOUN CO.,LTD. (KNZ)\n\nADDRESS: 11, MINATO 4-CHOME, KANAZAWA-CITY , ISHIKAWA , JAPAN (POSTAL CODE : 920-0211)', rows: '3' },

        { area: 'MAIZURU', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-773-75-5371', email: 'mrt@iinokoun.com' },
        { address: 'IINO KOUN CO.,LTD. (MAI)\n\nADDRESS: 18-7, SHOUIN, MAIZURU-CITY,KYOTO,JAPAN,(POSTAL CODE : 624-0931)', rows: '3' },

        { area: 'MIZUSHIMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import / Export Documentation', officeTel: '81-86-523-5551', email: 'ship-agent@nakatani.co.jp' },
        { address: 'NAKATANI KOUN CO.,LTD. (MIZ)\n\nADDRESS: 8262-2 SHIN-MINATO, TAMASHIMA-OTOSHIMA, KURASHIKI-SHI, OKAYAMA, JAPAN (POSTAL CODE :  713-8103)', rows: '3' },

        { area: 'MOJI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Documentation(Import/Export)', officeTel: '81-93-321-3999', email: 'mojiagent@sankyu.co.jp' },
        { address: 'SANKYU INC. MOJI BRANCH (MOJ)\n\nADDRESS : 72 TACHINOURAKAIGAN MOJI-KU KITAKYUSHU-CITY, FUKUOKA , JAPAN (POSTAL CODE : 801-0805)', rows: '3' },

        { area: 'MURORAN', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-143-24-3101', email: 'y.ota@narasaki-stax.co.jp', address: 'NARASAKI STAX CO.,LTD. MURORAN BRANCH (MUR) 147-8, TSUKIJI-CHO, MURORAN-CITY HOKKAIDO , JAPAN (POSTAL CODE :051-0031)' },
        { address: 'SANKYU INC. MOJI BRANCH (MOJ)\n\nADDRESS : 72 TACHINOURAKAIGAN MOJI-KU KITAKYUSHU-CITY, FUKUOKA , JAPAN (POSTAL CODE : 801-0805)', rows: '3' },

        { area: 'MATSUYAMA', cnt: '3', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-89-952-2341', email: 'aya.ikuta@nipponexpress.com' },
        { team: 'SHIPPING AGENCY TEAM', job: '', officeTel: '81-89-952-2341', email: 'risako.yoshimune@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO., LTD. MATSUYAMA OFFICE (MYJ)\n\nADDRESS: 1455-13 , KAIGANDOORI , MATSUYAMA-CITY, EHIME, JAPAN (POSTAL CODE : 791-8058)', rows: '3' },

        { area: 'NAOETSU', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-25-544-5765', email: 'nittsu-naoetsu_150055@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD.NAOETSU MARINE OFFICE (NAO)\n\nADDRESS: 2-9-4, MINATO-CHO, JYOETSU-CITY, NIIGATA, JAPAN (POSTAL CODE :942-0013)', rows: '3' },

        { area: 'NAGOYA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Documentation(Import/Export)', officeTel: '81-52-652-2181', email: 'kamotsu@gkk.co.jp' },
        { address: 'GOYO KAIUN KAISHA., LTD. (NGO)\n\nADDRESS: 1-7-40, IRIFUNE, MINATO-KU, NAGOYA-CITY , AICHI , JAPAN (POSTAL CODE : 455-0032)', rows: '3' },

        { area: 'NAGASAKI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Documentation(Import/Export)', officeTel: '81-95-895-7651', email: 'n12kaiun@crux.ocn.ne.jp' },
        { address: 'NIPPON EXPRESS CO., LTD NAGASAKI BRANCH (NGS)\n\nADDRESS: 3-76-91, OGAKURACHO ,NAGASAKI-CITY,NAGASAKI, JAPAN (POSTAL CODE : 850-0961)', rows: '3' },

        { area: 'OITA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Documentation(Import/Export)', officeTel: '81-97-524-2118', email: 'nittsu-oita_agent@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD.OITA SHIPPING BRANCH\n\nADDRESS: 6 OZAI,OITA-CITY,OITA,JAPAN (POSTAL CODE :870-0266)', rows: '3' },

        { area: 'ONAHAMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Documentation(Import/Export)', officeTel: '81-246-75-0210', email: 'agent@iocs.co.jp' },
        { address: 'ONAHAMA KAIRIKU UNSO KAISHA. LTD.\n\nADDRESS: 196 OTSURUGI, SHIMOGAWA,IZUMI-MACHI, IWAKI-CITY, FUKUSHIMA-PREF. JAPAN (POSTAL CODE : 971-8183)', rows: '3' },

        { area: 'OSAKA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-6-6615-8540', email: 'nittsu-koun@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. OSAKA INT\'L TRANSPORT BRANCH\n\nADDRESS: 8-7-21 Nankonaka Suminoe-ku, Osaka Japan (POSTAL CODE : 559-0033)', rows: '3' },

        { area: 'SHIBUSHI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-099-472-1121', email: 'nittsu-sbssp@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. SHIBUSHI BRANCH /MARINE DEPT.\n\nADDRESS: 3276-1 AZA-WAKAHAMA SHIBUSHI SHIBUSHI-CHO SHIBUSHI-CITY KAGOSHIMA,JAPAN (POSTAL CODE : 899-7103)', rows: '3' },

        { area: 'SATSUMA SENDAI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-996-26-3335', email: 'eigyou-g@bussan.co.jp' },
        { address: 'CHUETSU BUSSAN CO.,LTD\n\nADDRESS: 360-21 MATSUBARA MINATO MACHI SATSUMASENDAI CITY KAGOSHIMAM JAPAN (POSTAL CODE : 899-1924)', rows: '3' },

        { area: 'SENDAI MIYAGI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-22-254-0948', email: 'kmtu@shiogama-koun.co.jp' },
        { address: 'NX SENDAISHIOGAMA KOUN CO.,LTD.\n\nADDRESS: 3F,105,MACHI,GAMOU,MIYAGINO-KU,SENDAI-CITY, MIYAGI,JAPAN (POSTAL CODE : 983-0002)', rows: '3' },

        { area: 'SAKATA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-234-35-1288', email: 'shin.sato@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. SAKATA BRANCH.\n\nADDRESS: NO. 1-31,AZA JIHACHIROBATAKE OAZA MIYAUMI SAKATA -CITY YAMAGATA , JAPAN (POSTAL CODE :998-0005)', rows: '3' },

        { area: 'SAKAIMINATO', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-859-42-2124', email: 'agt-1@sakai-kairiku.co.jp' },
        { address: 'SAKAIMINATO KAIRIKU UNSO.,LTD.\n\nADDRESS: 12-8-1 SHOWA-MACHI, SAKAIMINATO-CITY, TOTTORI-PREF.,JAPAN (POSTAL CODE : 684-0034)  (AGENCY SECT.)', rows: '3' },

        { area: 'SHIMIZU', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-54-352-1785', email: 'hakueiag@pdcc.co.jp' },
        { address: 'HAKUEI TRANS CO.,LTD.\n\nADDRESS: 2-4-8 MANSEI-CHO SHIMIZU-KU, SHIZUOKA CITY, SHIZUOKA ,JAPAN(POSTAL CODE : 424-0826)', rows: '3' },

        { area: 'TAKAMATSU', cnt: '3', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-87-822-5184', email: 'agency@takshoun.co.jp' },
        { team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-87-822-5184', email: 'yokoyama@takshoun.co.jp' },
        { address: 'TAKAMATSU-SHOUN CORPORATION\n\nADDRESS: 10-1, ASAHI SHINMACHI TAKAMATSU CITY, JAPAN (POSTAL CODE :760-0064)', rows: '3' },

        { area: 'TOYOHASHI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-532-32-1048', email: 'container@aichikaiunsangyo.co.jp' },
        { address: 'AICHIKAIUN SANGYO CO.,LTD.\n\nADDRESS: 3-19, JINNO FUTO-CHO, TOYOHASHI, AICHI,JAPAN (POSTAL CODE :441-8057)', rows: '3' },

        { area: 'TOKUSHIMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-885-35-7010', email: 'kyodotks@mc.pikara.ne.jp' },
        { address: 'KYODO KOUN & CO.,LTD.\n\nADDRESS: 401, WADATSUBIRAKI-CHO, AZAKITA,KOMATSUSHIMA CITY TOKUSHIMA,JAPAN (POSTAL CODE : 773-0020)', rows: '3' },

        { area: 'TOKUYAMA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-834-27-0202', email: 'nittsu-shunan_agency@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD.\n\nADDRESS: TOKUYAMA SHIPPING BRANCH7-8, CHIKKO-CHO, SHUNAN-CITY, YAMAGUCHI-PREF, JAPAN (POSTAL CODE :745-0025)', rows: '3' },

        { area: 'TOMAKOMAI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-145-26-8511', email: 'nscy@narasaki-stax.co.jp' },
        { address: 'NARASAKI STAX CO.,LTD.\n\nADDRESS: 534-2, BENTEN , AZA , TOMAKOMAI-CITY , HOKKAIDO , JAPAN (POSTAL CODE :059-1371)', rows: '3' },

        { area: 'TOYAMASHINKO', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-766-83-7325', email: 'pa-jptos@ekmtc.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. TOYAMASHINKO INT\'L TRANSPORT BRANCH\n\nADDRESS: 1003, KOSHINOKATAMACHI,IMIZU-SHI, TOYAMA, JAPAN (POSTAL CODE :934-0024)', rows: '3' },

        { area: 'TSURUGA', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-770-24-5522', email: 'contena@tsurugakairiku.co.jp' },
        { address: 'TSURUGA KAIRIKU UNYU KAISHA,LTD.\n\nADDRESS: 2-10,SAKURAMACHI,TSURUGA-SHI,FUKUI,JAPAN (POSTAL CODE :914-0078)', rows: '3' },

        { area: 'YOKOHAMA', cnt: '4', team: 'SHIPPING AGENCY TEAM', job: 'Local agent', officeTel: '81-45-623-3014', email: 'nittsu-yokagent@nipponexpress.com' },
        { team: 'DOCUMENT', job: 'Import Documents', officeTel: '81-45-623-3024', email: 'nittsu-keihindoc@nipponexpress.com' },
        { team: 'DOCUMENT', job: 'Export Documets', officeTel: '81-45-623-3024', email: 'nittsu-kdc_doc@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. KEIHIN PORT TERMINAL BRANCH(YOK/TYO)\n\nADDRESS: DAIKOKU C-3 TERMINAL OFFICE 4TH.,DAIKOKU,FUTO 21 TSURUMI-KU YOKOHAMA CITY , KANAGAWA , JAPAN (POSTAL CODE : 230-0054)', rows: '3' },

        { area: 'YATSUSHIRO', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Sales, Documentation', officeTel: '81-965-37-0011', email: 'pa-jpyat@ekmtc.com' },
        { address: 'NIPPON EXPRESS CO.,LTD. YATSUSHIRO BRANCH\n\nADDRESS : NO.4-1, 2-CHOME , SHINMINATO-MACHI , YATSUSHIRO-CITY , KUMAMOTO, JAPAN (POSTAL CODE : 866-0034 )', rows: '3' },

        { area: 'YOKKAICHI', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-59-361-7702', email: 'contimp@trancy.co.jp' },
        { address: 'JAPAN TRANSCITY CORPORATION\n\nADDRESS : KASUMI 2-2, YOKKAICHI-CITY, MIE , JAPAN (POSTAL CODE :510-0011)', rows: '3' },

        { area: 'KOBE', cnt: '2', team: 'SHIPPING AGENCY TEAM', job: 'Import Documents / Export Documets', officeTel: '81-78-230-6107', email: 'nittsu-koun@nipponexpress.com' },
        { address: 'NIPPON EXPRESS CO.,LTD.KOBE INTERNATIONAL TRANSPORT BRANCH\n\nADDRESS : URBAN ACE 7 FLOOR ,4-1-22 ONOE-DORI,CHUO-KU KOBE JAPAN (POSTAL CODE :651-0088)', rows: '3' }
      ]
    },
    {
      key: '03',
      keyNm: 'CN',
      info: [
          { area: 'SHANGHAI', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-21-63092696', email: 'shasa@ekmtc.com' },
          { team: 'BSA Control Team', job: 'Booking & Space control', officeTel: '86-21-63098390', email: 'shabk@ekmtc.com' },
          { team: 'Documentation Team', job: 'Outbound Document,Customer Service', officeTel: '86-21-63091526', email: 'shaed@ekmtc.com' },
          { team: 'Documentation Team', job: 'Inbound Document,Customer Service', officeTel: '86-21-63091692', email: 'shaid@ekmtc.com' },
          { address: 'KOREA MARINE TRANSPORT (CHINA-SHA) CO.,LTD\n\nADDRESS: RM 2905, BLOCK A, HELEN CENTER BLDG,440 HAILUN\nROAD, HONGKOU DISTRICT SHANGHAI P.R.C (POST CODE: 200080)', rows: '4' },

          { area: 'Yangtze River\n(NANJING, WUHAN, CHONGQING)', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-25-58808287', email: 'yzrsa@ekmtc.com' },
          { team: 'Documentation Team', job: 'Booking & Outbound Document & Customer service', officeTel: '86-25-58809181', email: 'yzrcs@ekmtc.com' },
          { team: 'Documentation Team', job: 'Inbound Document & Customer service', officeTel: '86-25-58808291', email: 'theaxia@ekmtc.com' },
          { address: 'KMTC NANJING BRANCH\nADDRESS: ROOM 1658-1660, World Trade Centre , NO.2 HANZHONG ROAD , GuLou District , NANJING, JIANGSU, CHINA, 210036\n\nKMTC WUHAN OFFICE\nADDRESS: No.R3, 17th Floor, No.250 Jianghan Road, Jiangan District, Wuhan\n\nKMTC CHONGQING OFFICE\nADDRESS: No.12, 17th Floor, Metropolitan Plaza, No.68 Zourong Road, Yuzhong District, Chongqing ', rows: '8' },

          { area: 'NINGBO', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-574-87683815', email: 'nbosa@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Outbound Document,Customer Service', officeTel: '86-574-87093198', email: 'nbocs@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Inbound Document,Customer Service', officeTel: '86-574-87093197', email: 'nbocs@ekmtc.com' },
          { address: 'KMTC NINGBO BRANCH\n\nADDRESS: RM 604, NINGBO TOWER OFFICE BUILDING, NEW WORLD PLAZA,\nNO. 51, SANYANQIAO STREET, YINZHOU DISTRICT, NINGBO CITY, ZHEJIANG PROVINCE, 315000 P.R.C', rows: '4' },

          { area: 'QINGDAO', cnt: '6', team: 'Sales Team', job: 'Sales & Marketing ', officeTel: '86-532-85780325', email: 'taosa@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Customer Service', officeTel: '86-532-85780522', email: 'taocs@ekmtc.com' },
          { team: 'Outbound Booking Team', job: 'Booking', officeTel: '86-532-85793319', email: 'taobk@ekmtc.com' },
          { team: 'Documentation Team', job: 'Outbound Documentation', officeTel: '86-832-85781882', email: 'taoed@ekmtc.com' },
          { team: 'Documentation Team', job: 'Inbound Documentation', officeTel: '86-532-85793722', email: 'taoid@ekmtc.com' },
          { address: 'KMTC QINGDAO BRANCH\n\nADDRESS: ROOM 3301B, QINGDAO INTERNATIONAL FINANCIAL CENTER,\nNO.59, HONGKONG MIDDLE ROAD, QINGDAO, P.R.C. (POST CODE: 266071)', rows: '4' },

          { area: 'LIANYUNGANG', cnt: '2', team: 'Customer Service Team', job: 'Export&Import Doc', officeTel: '86-518-82233750', email: 'lyg@ekmtc.com' },
          { address: 'KMTC LIANYUNGANG OFFICE\n\nADDRESS: 18TH FLOOR, JIANGSU JINHAI INTERNATIONAL BUSSINESS BUILDING,\n NO. 189, HAITANG NORTH ROAD, LIANYUN DISTRICT, LIANYUNGANG, P.R.C. ', rows: '4' },

          { area: 'XINGANG, TIANJIN', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-22-23303484*8007', email: 'xggsa@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Inbound Document,Customer Service', officeTel: '86-22-23303484*8009', email: 'xggcs@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Outbound Document,Customer Service', officeTel: '86-22-23303484*8000', email: 'xggcs@ekmtc.com' },
          { address: 'KMTC XINGANG BRANCH\n\nADDRESS: ROOM 1601, TIANJIN INT\'L BUILDING, NO.75 NANJING ROAD,\nHEPING DISTRICT, TIANJIN, P.R.C. (POST CODE: 300050)', rows: '4' },

          { area: 'DALIAN', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-411-82580960', email: 'dlcsa@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Booking & Outbound Document & Customer service', officeTel: '86-411-82580906', email: 'dlccs@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Inbound Document & Customer service', officeTel: '86-411-82580987', email: 'dlccs@ekmtc.com' },
          { address: 'KMTC DALIAN BRANCH\n\nADDRESS: ROOM 2702-03 RAINBOW BUILDING NO.23,\nRENMIN ROAD ZHONGSHAN DISTRICT, DALIAN, P.R.C. (POST CODE: 116001)', rows: '4' },

          { area: 'SHENZHEN', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-755-82562515', email: 'szpsa@ekmtc.com' },
          { team: 'Booking Team', job: 'Booking,Customer Service', officeTel: '86-755-82562505', email: 'szpcs@ekmtc.com' },
          { team: 'Documentation Team', job: 'Outbound Document,Customer Service', officeTel: '86-755-82562501', email: 'szped@ekmtc.com' },
          { team: 'Documentation Team', job: 'Inbound Document,Customer Service', officeTel: '86-755-82562507', email: 'szpid@ekmtc.com' },
          { address: 'KMTC SHENZHEN BRANCH\n\nADDRESS: RM 1705, 17/F, EXCELLENCE TIMES SQUARE,\n 4068 YI TIAN ROAD, FU TIAN DISTRICT, SHENZHEN, P.R.C. ', rows: '4' },

          { area: 'GUANG ZHOU', cnt: '3', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-20-38262441-305', email: 'cansa@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Document,Customer Service', officeTel: '86-20-38262441-316', email: 'caned@ekmtc.com' },
          { address: 'KMTC GUANGZHOU BRANCH\n\nADDRESS: UNIT 1905, GUANGZHOU INTERNATIONAL COMMERCIAL CENTER,\n235 TIANHE ROAD NORTH, TIANHE DISTRICT, GUANGZHOU, GUANGDONG, P.R.C. ', rows: '4' },

          { area: 'ZHONGSHAN', cnt: '3', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-760-88239775', email: 'zsnsa@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Customer Service', officeTel: '86-760-88239776', email: 'zsncs@ekmtc.com' },
          { address: 'KMTC ZHONGSHAN OFFICE\n\nADDRESS: ROOM 419, BUILDING 7 OF XINGHUIWAN,\nZHONGSHAN 2 ROAD, ZHONGSHAN, GUANGDONG, P.R.C. ', rows: '4' },

          { area: 'XIAMEN', cnt: '3', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '(86) 592-2680062', email: 'xmnsa@ekmtc.com' },
          { team: 'Customer Service Team', job: 'Document,Customer Service', officeTel: '(86) 592-2685822', email: 'xmncs@ekmtc.com' },
          { address: 'KMTC XIAMEN BRANCH\n\nADDRESS: NO.11-12A 21FL.,THE BANK CENTRE NO.189 XIAHE ROAD, XIAMEN, FUJIAN, P.R.C. ', rows: '3' },

          { area: 'FUZHOU (MAWEI)', cnt: '3', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '86-591-87836208', email: 'tangym@penavicofz.com.cn' },
          { team: 'Customer Service Team', job: 'Customer Service', officeTel: '86-591-88522335', email: 'liner@penavicofz.com.cn' },
          { address: 'CHINA OCEAN SHIPPING AGENCY FUZHOU\n\nADDRESS: 12F, WORLDWIDE PLAZA, NO.158, WUSI ROAD, FUZHOU, P.R.C. (POST CODE: 350003)', rows: '3' },

          { area: 'SHANTOU (IN GUANGDONG)', cnt: '2', team: 'Sales Team, Customer Service Team', job: 'Sales & Marketing, Customer Service', officeTel: '86-754-88737623', email: 'kmtcteam@penavicost.com' },
          { address: 'CHINA OCEAN SHIPPING AGENCY SHANTOU\n\nADDRESS : 23/F., CHAOSHAN GALAXY BUILDING, JINHUAN ROAD, SHANTOU, GUANGDONG, P.R.C. (POST CODE: 515041)', rows: '3' },

          { area: 'KEELUNG', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '(886) 2-27133788', email: 'jackychang@tvs.tvlgroup.com.tw' },
          { team: 'Customer Service Team', job: 'Customer Service', officeTel: '(886) 2-27133788', email: 'saraliu@tvs.tvlgroup.com.tw' },
          { team: 'Documentation Team', job: 'Outbound Documentation', officeTel: '(886) 2-27133788', email: 'mandychen@tvs.tvlgroup.com.tw' },
          { team: 'Documentation Team', job: 'Inbound Documentation', officeTel: '(886) 2-27133788', email: 'alextasi@tvs.tvlgroup.com.tw' },
          { address: 'TRANS VANTAGE SHIPPING AGENCY CO., LTD.(TPE OFFICE)\n\nADDRESS: 11TH FLOOR , NO.217 SEC. 3 NANKING EAST ROAD , TAIPEI , TAIWAN (POST CODE: 104506)', rows: '3' },

          { area: 'TAICHUNG', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '(886) 4-2299-3828', email: 'mikalin@tvstch.tvlgroup.com.tw' },
          { team: 'Customer Service Team', job: 'Outbound CS & DOCS', officeTel: '(886) 4-2299-3828', email: 'estherchen6041@tvstch.tvlgroup.com.tw' },
          { team: 'Customer Service Team', job: 'Inbound CS & DOCS', officeTel: '(886) 4-2299-3828', email: 'emmapan@tvstch.tvlgroup.com.tw' },
          { address: 'TRANS VANTAGE SHIPPING AGENCY CO., LTD. (TXG OFFICE)\n\nADDRESS: 15F-6 , NO.241 SEC.3, WEN HSIN ROAD, TAICHUNG, TAIWAN (POST CODE: 40753)', rows: '3' },

          { area: 'KAOHSIUNG', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '886-7536-06127615 Ext. 7615', email: 'kevinchen000698@tvskao.tvlgroup.com.tw' },
          { team: 'Customer Service Team', job: 'Outbound CS & DOCS', officeTel: '(886) 7536-0612 Ext. 7614', email: 'estherchen6041@tvstch.tvlgroup.com.tw' },
          { team: 'Customer Service Team', job: 'Inbound CS & DOCS', officeTel: '(886) 7536-0612 Ext. 7607', email: 'emmapan@tvstch.tvlgroup.com.tw' },
          { address: 'TRANS VANTAGE SHIPPING AGENCY CO., LTD. (KHH OFFICE)\n\nADDRESS: 15F B2 , NO.29 , HAI PIEN ROAD , KAOHSIUNG , TAIWAN (POST CODE: 802608)', rows: '3' }
        ]
    },
    {
      key: '04',
      keyNm: 'HK',
      info: [
        { area: 'HONG KONG', cnt: '6', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '(852) 2851-9675', email: 'hkgsa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service - Export Booking', officeTel: '(852) 2851-9675', email: 'hkgbk@ekmtc.com' },
        { team: 'Documentation Team', job: 'Outbound Documentation', officeTel: '(852) 2805-2376', email: 'hkged@ekmtc.com' },
        { team: 'Documentation Team', job: 'Inbound Documentation', officeTel: '(852) 2851-9638', email: 'hkgid@ekmtc.com' },
        { team: 'Transhipment Team', job: 'Transhipment Cargo', officeTel: '(852) 2534-5672', email: 'hkgts@ekmtc.com' },
        { address: 'KMTC (HK) CO. LTD\n\nADDRESS: 2502-04,25/F., MANHATTAN PLACE, 23 WANG TAI ROAD, KOWLOON BAY, KOWLOON, HONG KONG', rows: '3' }
      ]
    },
    {
      key: '05',
      keyNm: 'PH',
      info: [
        { area: 'MANILA', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing ', officeTel: '(632) 8354-0940 to 49 / (632) 8518-0179', email: 'mnlsa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Outbound Customer Service, Export Booking and Outbound Documentation', officeTel: '(632) 8354-0940 to 49 / (632) 8518-0179', email: 'mnlcs@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Inbound Customer Service, Import Releasing and Inbound Documentation', officeTel: '(632) 8354-0940 to 49 / (632) 8353-1904', email: 'mnlid@ekmtc.com' },
        { address: 'KMTC (PHILIPPINES) CORPORATION\n\nADDRESS: 9/F TIMES PLAZA BLDG., U.N. AVE. COR. TAFT AVE., ERMITA, MANILA, PHILIPPINES (POSTAL CODE : 100', rows: '3' }
      ]
    },
    {
      key: '06',
      keyNm: 'SG',
      info: [
        { area: 'SINGAPORE', cnt: '6', team: 'Sales Team', job: 'Sales & Marketing ', officeTel: '(065) 6593 9111 /112/113/114', email: 'sinsa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Export Booking,Export DG & OOG Declaration, Export Shipping Cert.', officeTel: '(065) 6593 9110 /109/108/107', email: 'sincs@ekmtc.com' },
        { team: 'Documentation Team Outbound Documentation', job: 'Export Documentation Matter', officeTel: '(065) 6593 9121/125/128', email: 'sined@ekmtc.com' },
        { team: 'Documentation Team Inbound Documentation', job: 'Inbound Documentation Matter', officeTel: '(065) 6593 9123 /124/120', email: 'sinid@ekmtc.com' },
        { team: 'Documentation Team Inbound & Outbound', job: 'Switch / 3rd Party Issuance', officeTel: '(065) 6593 9121 /123/124/125/128/120', email: 'sindc@ekmtc.com' },
        { address: 'KMTC (SINGAPORE) PTE. LTD\n\nADDRESS: 200 CANTONMENT ROAD, # 16-02A, SOUTHPOINT, SINGAPORE (POSTAL CODE : 089763)', rows: '3' }
      ]
    },
    {
      key: '07',
      keyNm: 'MY',
      info: [
        { area: 'PORT KLANG', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing ', officeTel: '603 - 3342 2287 Ext. 1306', email: 'pkgsa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '603 - 3342 2287 Ext. 1401', email: 'pkgcs@ekmtc.com' },
        { team: 'Documentation Team', job: 'Outbound Documentation', officeTel: '603 - 3342 2287 Ext. 1600', email: 'pkged@ekmtc.com' },
        { team: 'Documentation Team', job: 'Inbound Documentation', officeTel: '603 - 3342 2287 Ext. 1700', email: 'pkgid@ekmtc.com' },
        { address: 'KMTC LINE (MALAYSIA) SDN. BHD.\n\nADDRESS: SUITE P2.02, LEVEL P2, MENARA TREND, INTAN MILLENNIUM SQUARE,\nNO. 68, JALAN BATAI LAUT 4, TAMAN INTAN, 41300 KLANG, SELANGOR DARUL EHSAN, MALAYSIA.', rows: '4' },

        { area: 'PASIR GUDANG', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing ', officeTel: '607 - 3511 741', email: 'pgusa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '607 - 3511 741', email: 'pgucs@ekmtc.com ' },
        { team: 'Documentation Team', job: 'Outbound Documentation', officeTel: '607 - 3511 741', email: 'pgued@ekmtc.com' },
        { team: 'Documentation Team', job: 'Inbound Documentation', officeTel: '607 - 3511 741', email: 'pguid@ekmtc.com' },
        { address: 'KMTC LINE (MALAYSIA) SDN. BHD. (PASIR GUDANG OFFICE)\n\nADDRESS: NO.89-01, JALAN MOLEK 3/1, TAMAN MOLEK, 81100 JOHOR BAHRU, JOHOR, MALAYSIA', rows: '3' },

        { area: 'PENANG', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing ', officeTel: '604-250 3228 Ext. 816', email: 'pensa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '604-250 3228 Ext. 819', email: 'pencs@ekmtc.com' },
        { team: 'Documentation Team', job: 'Outbound Documentation', officeTel: '604-250 3228 Ext. 814', email: 'pened@ekmtc.com' },
        { team: 'Documentation Team', job: 'Inbound Documentation', officeTel: '604-250 3228 Ext. 813', email: 'penid@ekmtc.com' },
        { address: 'KMTC LINE (MALAYSIA) SDN. BHD. (PENANG OFFICE)\n\nADDRESS: MWE PLAZA, 16TH FLOOR, SUITE 16.03, NO. 8, FARQUHAR STREET, 10200 PENANG, MALAYSIA.', rows: '3' }
      ]
    },
    {
      key: '08',
      keyNm: 'ID',
      info: [
        { area: 'JAKARTA', cnt: '8', team: 'Sales Team', job: 'Sales Inbound', officeTel: '62-21-3950 4900 Ext.117', email: 'jktsa@ekmtc.com' },
        { team: 'Sales Team', job: 'Sales outbound', officeTel: '62-21-3950 4900 Ext.114', email: 'jktsa@ekmtc.com' },
        { team: 'Export Customer Service Team', job: 'Export CS (booking matter)', officeTel: '62-21-3950 4900 Ext.157', email: 'jktced@ekmtc.com' },
        { team: 'Documentation Team', job: 'Export Documentation', officeTel: '62-21-3950 4900 Ext.156', email: 'jktced@ekmtc.com' },
        { team: 'Documentation Team', job: 'Import Docs. (Manifest, NOA, TS Cargo)', officeTel: '62-21-3950 4900 Ext.175', email: 'jktcid@ekmtc.com' },
        { team: 'Customer Service Team(Front)', job: 'C/S Front Line (D/O Import)', officeTel: '62-21-3950 4900 Ext. 169', email: 'do.jkt@ekmtc.com' },
        { team: 'Indonesia Outport', job: 'Sales I/B & O/B', officeTel: '62-21-3950 4900 Ext. 120', email: 'outport.sales@ekmtc.com' },
        { address: 'KMTC INDONESIA PT. SAMUDERA AGENCIES INDONESIA KMTC DIVISION - JAKARTA OFFICE\n\nADDRESS: LIPPO KUNINGAN BUILDING, 21ST FLOOR, JL. H.R. RASUNA SAID KAV.B-12, KUNINGAN,\nJAKARTA SELATAN 12940, INDONESIA', rows: '4' },

        { area: 'SURABAYA', cnt: '6', team: 'Sales Team', job: 'Sales/Marketing', officeTel: '62-31-5349977 Ext.8094', email: 'sales.sub@ekmtc.com' },
        { team: 'Customer Service Team', job: 'C/S (Booking, space, Export)', officeTel: '62-31-5349977 Ext.8050', email: 'sub.cs@ekmtc.com' },
        { team: 'Documentation Team', job: 'Export Documentation', officeTel: '62-31-5347899 Ext.8075', email: 'doc.sub@ekmtc.com' },
        { team: 'Documentation Team', job: 'Doc Import, T/S Cargo', officeTel: '62-31-5347899 Ext.8073', email: 'imp.sub@ekmtc.com' },
        { team: 'Customer Service Team(Front)', job: 'C/S Front line (D/O Import)', officeTel: '62-31-5347899 Ext.8052', email: 'doc.sub@ekmtc.com' },
        { address: 'KMTC INDONESIA PT. SAMUDERA AGENCIES INDONESIA KMTC DIVISION - SURABAYA BRANCH\n\nADDRESS: PLAZA BRI, SUITE 1801, JL. BASUKI RAHMAT 122-138, SURABAYA 60271', rows: '3' },

        { area: 'BANDUNG', cnt: '2', team: 'Shipping Agency', job: 'Sales, CS & Doc', officeTel: '62-22-7507210 Ext. 6040/4461', email: 'kmtc.bdg@samudera.id' },
        { address: 'ADDRESS: JL. VENUS BARAT, KAV. 12/12A. METRO ESTATE, MARGAHAYU RAYA', rows: '2' },

        { area: 'SEMARANG', cnt: '2', team: 'Shipping Agency', job: 'Sales, CS & Doc', officeTel: '62-24-3544929 Ext. 6070/73/74', email: 'kmtc.srg@samudera.id' },
        { address: 'ADDRESS: SAMUDERA INDONESIA BUILDING 3RD FLOOR, JL. PERKUTUT NO.2,\n\nKOTA LAMA TANJUNG MAS, SEMARANG UTARA 50174 - JAWA TENGAH', rows: '3' },

        { area: 'BELAWAN & KUALA TANJUNG', cnt: '2', team: 'Shipping Agency', job: 'Sales, CS & Doc', officeTel: '62-24-6940044 Ext. 6016/33/35', email: 'kmtc.blw@samudera.id' },
        { address: 'PT. SAMUDERA AGENCIES INDONESIA\n\nADDRESS: SAMUDERA LOGISTIC CENTRE BUILDING, JL. RAYA PELABUHAN GABION,\n BELAWAN - MEDAN, 20414', rows: '4' },

        { area: 'PALEMBANG', team: 'Indonesia Outport', job: 'Sales I/B & O/B', officeTel: '62-21-3950 4900 Ext. 121-22', email: 'outport.sales@ekmtc.com', areaComb: 'Y' },
        { area: 'JAMBI & BENGKULU' },
        { area: 'PANJANG' },
        { area: 'PADANG' },
        { area: 'BATAM' },
        { area: 'PONTIANAK' },
        { area: 'BANJARMASIN' },
        { area: 'BALIKPAPAN' },
        { area: 'SAMARINDA' },
        { area: 'UJUNG PANDANG' },
        { address: 'KMTC INDONESIA PT. SAMUDERA AGENCIES INDONESIA KMTC DIVISION - JAKARTA OFFICE\n\nADDRESS: LIPPO KUNINGAN BUILDING, 21ST FLOOR, JL. H.R. RASUNA SAID KAV.B-12, KUNINGAN,\nJAKARTA SELATAN 12940, INDONESIA', rows: '4' }
      ]
    },
    {
      key: '09',
      keyNm: 'TH',
      info: [
        { area: 'BANGKOK', cnt: '7', team: 'Outbound Sales', job: 'Sales Outbound ', officeTel: '662-120-9524/9528', email: 'bkksa@ekmtc.com' },
        { team: 'Inbound Sales', job: 'Sales Inbound ', officeTel: '662-120-9551', email: 'bkkis@ekmtc.com' },
        { team: 'Customer Service Team', job: 'export booking receiving /etc', officeTel: '662-120-9530/9539', email: 'bkkcs@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Inbound Customer Service ', officeTel: '662-120-9557/9561', email: 'bkkid@ekmtc.com' },
        { team: 'Document Team', job: 'B/L docs with outbound', officeTel: '662-120-9540/9548', email: 'bkked@ekmtc.com' },
        { team: 'Document Team', job: 'Inbound Document', officeTel: '662-120-9564/9568', email: 'bkkid@ekmtc.com' },
        { address: 'KMTC (THAILAND) CO., LTD\n\nADDRESS: 3195/13 8TH FLOOR, VIBULTHANI TOWER 1, RAMA 4 RD., KLONGTON, KLONGTOEY BANGKOK, THAILAND. (POSTAL CODE: 10110)', rows: '3' }
      ]
    },
    {
      key: '10',
      keyNm: 'VN',
      info: [
        { area: 'HOCHIMINH', cnt: '5', team: 'Sales & Marketing Team', job: 'Sales Outbound, Inbound', officeTel: '84-28-38246116 Ext.108~113', email: 'sgnsa@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Outbound / Booking', officeTel: '84-28-38246116 Ext.102~106', email: 'sgncs@ekmtc.com' },
        { team: 'Documentation Team', job: 'Outbound', officeTel: '84-28-38246116 Ext.115~118', email: 'sgned@ekmtc.com' },
        { team: 'Documentation Team', job: 'Inbound', officeTel: '84-28-38246116 Ext.121~125', email: 'sgnid@ekmtc.com' },
        { address: 'KMTC (VIETNAM) CO.,LTD.\n\nADDRESS: MELINH POINT TOWER, SUITE 1401-4, 14TH FL.,\n2 NGO DUC KE STR., DISTRICT 1, HOCHIMINH CITY, VIETNAM', rows: '4' },

        { area: 'HAIPHONG', cnt: '4', team: 'Customer Service Team', job: 'Outbound / Booking', officeTel: '84-225-3686808 Ext. 105,106, 108', email: 'hphcs@ekmtc.com' },
        { team: 'Documentation Team', job: 'Outbound', officeTel: '84-225-3686808 Ext.119', email: 'hphed@ekmtc.com' },
        { team: 'Documentation Team', job: 'Inbound', officeTel: '84-225-3686808 Ext.102, 104, 116', email: 'hphid@ekmtc.com' },
        { address: 'KMTC (VIETNAM) CO.,LTD – HAIPHONG BRANCH\n\nADDRESS: HARBOUR VIEW TOWER, UNIT C&D, 2ND FLOOR,\n12 TRAN PHU STR., NGO QUYEN DIST., HAIPHONG CITY., VIETNAM', rows: '4' },

        { area: 'HANOI', cnt: '3', team: 'Sales Team', job: 'Sales Outbound, Inbound', officeTel: '84-24-35746732', email: 'hansa@ekmtc.com' },
        { team: 'Documentation Team', job: 'Outbound', officeTel: '84-24-35746732', email: 'hphed@ekmtc.com' },
        { address: 'KMTC (VIET NAM) CO CO.,LTD – HA NOI OFFICE\n\nADDRESS: OCEAN PARK BUILDING, ROOM 1103, 11TH FLOOR,\n01 DAO DUY ANH STR., DONG DA DIST., HANOI., VIETNAM', rows: '4' }
      ]
    },
    {
      key: '11',
      keyNm: 'BD',
      info: [
        { area: 'CHATTOGRAM', cnt: '9', team: 'Zannat Sultana', job: 'Assistant General Manager (Trade)', officeTel: '(880) 031 720414', email: 'zannat@ctg.qc-group.com' },
        { team: 'Nasir Uddin Khondaker ', job: 'Assistant General Manager (Import)', officeTel: '(880) 031 718286', email: 'nasir.uddin@ctg.qc-group.com ' },
        { team: 'Momita Begum', job: 'Assistant Manager (Export Sales )', officeTel: '(880) 02 223381408', email: 'momita@qcshipping.net  ' },
        { team: 'Kamrul Hasan Sarker', job: 'Senior Executive (Customer Service,dhaka )', officeTel: '(880) 02 223381408', email: 'kamrul@qcshipping.net' },
        { team: 'Borhan Uddin', job: 'Jr. Executive(Documentation,dhaka)', officeTel: '(880) 02 223381408', email: 'borhan@qcshipping.net' },
        { team: 'Bidhan Paul', job: 'Customer Service(Export)', officeTel: '(880) 02333313140-2', email: 'bidhanqcsl@ctg.qc-group.com ' },
        { team: 'shahadat Hossain', job: 'Jr. Executive( Customer Service & Docs,Export)', officeTel: '(880) 02333313140-2', email: 'shahed@ctg.qc-group.com' },
        { team: 'Morshed Alam', job: 'Jr. Executive(Import Documentation)', officeTel: '(880) 02333313140-2', email: 'morshed@ctg.qc-group.com' },
        { address: 'QC SHIPPING LTD\n\nADDRESS: SHAFI BHABAN(3RD FLOOR), AGRABAD C/A, CHOTTOGRAM, BANGLADESH', rows: '3' }
      ]
    },
    {
      key: '12',
      keyNm: 'IN',
      info: [
        { area: 'MUMBAI PORT', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-22-49222541', email: 'mumsa@ekmtc.com' },
        { team: 'Document Team', job: 'Outbound Document', officeTel: '91-22-49222520', email: 'mumed@ekmtc.com' },
        { team: 'Document Team', job: 'Inbound Document', officeTel: '91-22-49222526', email: 'mumid@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '91-22-49222516', email: 'mumcs@ekmtc.com' },
        { address: 'KMTC (INDIA) PRIVATE LIMITED\n\nADDRESS: ONE INTERNATIONAL CENTRE, TOWER-3, 22ND FLOOR,\nSENAPATI BAPAT MARG, PRABHADEVI (WEST), MUMBAI-400013, INDIA', rows: '4' },

        { area: 'DELHI', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-11-43121708', email: 'delsa@ekmtc.com' },
        { team: 'Document Team', job: 'Documentation', officeTel: '91-11-43121712', email: 'deldoc@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '91-11-43121710', email: 'delcs@ekmtc.com' },
        { address: 'KMTC (INDIA) PRIVATE LIMITED NEW DELHI BRANCH\n\nADDRESS: 1201-1202, DLF TOWER - A, 12TH FLOOR, JASOLA DISTRICT CENTRE, NEW DELHI -110025', rows: '3' },

        { area: 'MUNDRA', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-28-36297012', email: 'gdmsa@ekmtc.com' },
        { team: 'Document Team', job: 'Documentation', officeTel: '91-28-36297013', email: 'gdmdoc@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '91-28-36297011', email: 'gdmcs@ekmtc.com' },
        { address: 'KMTC (INDIA) PRIVATE LIMITED GANDHIDHAM BRANCH\n\nADDRESS: RABINDRANATH TAGORE RD, PLOT NO. 335, I AND II FLOOR, SECTOR 1A, \nNEAR OLSO CIRCLE, GANDHIDHAM,  KUTCHH GUJARAT 370201', rows: '4' },

        { area: 'ICD AHMEDABAD', cnt: '2', team: 'Branch', job: 'All', officeTel: ' 91-79-48967003', email: 'amd@ekmtc.com' },
        { address: 'KMTC (INDIA) PRIVATE LIMITED AHMEDABAD BRANCH\n\nADDRESS : SAKAR-IX, A 1202B, BESIDE OLD RESERVE BANK OF INDIA, NEAR CITY GOLD,\nASHRAM RD, MUSLIM SOCIETY, NAVRANGPURA, AHMEDABAD, GUJARAT 380009', rows: '4' },

        { area: 'ICD LUDHIANA', cnt: '3', team: 'Sales Team', job: 'Sales & Marketing', officeTel: ' 91-161-4084823', email: 'lud@ekmtc.com' },
        { team: 'Document Team', job: 'Documentation', officeTel: ' 91-161-4084821', email: 'lud@ekmtc.com' },
        { address: 'KMTC (INDIA) PRIVATE LIMITED LUDHIANA BRANCH\n\nADDRESS: SCF 142, 2ND FLOOR, DECENT TOWER,\nURBAN ESTATE, PHASE-II, FOCAL POINT, LUDHIANA, PUNJAB141010.', rows: '4' },

        { area: 'CHENNAI', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-44-66067703', email: 'maasa@ekmtc.com' },
        { team: 'Document Team', job: 'Outbound Documentation', officeTel: '91-44-66067716', email: 'maaed@ekmtc.com' },
        { team: 'Document Team', job: 'Inbound Documentation', officeTel: '91-44-66067709', email: 'maaid@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '91-44-66067704', email: 'maacs@ekmtc.com' },
        { address: 'KMTC (INDIA) PRIVATE LIMITED CHENNAI BRANCH\n\nADDRESS : CHAITANYA IMPERIAL BUILDING, 2ND FLOOR,\nANNA SALAI ROAD, TEYNAMPET, CHENNAI 600018, TAMIL NADU', rows: '4' },

        { area: 'HAZIRA', cnt: '3', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-22-49222541', email: 'mumsa@ekmtc.com' },
        { team: 'Customer Service', job: 'Customer Service', officeTel: '91-22-49222521', email: 'mumcs@ekmtc.com' },
        { address: 'KMTC (INDIA) PRIVATE LIMITED HAZIRA/SURAT BRANCH\n\nADDRESS : 308, 3RD FLOOR, WHITE ORCHID,\nL.P. SAVANI ROAD, ADAJAN, SURAT, GUJARAT 395009', rows: '4' },

        { area: 'ICD BANGALORE', cnt: '2', team: 'OFFICE', job: 'Overall', officeTel: '91-80-41122339 / 40', email: 'ssablr@seahorsegroup.co.in' },
        { address: 'SEAHORSE SHIP AGENCIES PVT. LTD., BANGALORE  BRANCH\n\nADDRESS : NO 302, RAHEJA CHAMBERS,12,MUSEUM ROAD,BANGALORE 560001', rows: '3' },

        { area: 'COCHIN', cnt: '4', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-484-4077904', email: 'maheshgs@seahorsegroup.co.in' },
        { team: 'Document Team', job: 'Outbound Documentation', officeTel: '91-484-4077905', email: 'ssacch@seahorsegroup.co.in' },
        { team: 'Document Team', job: 'Inbound Documentation', officeTel: '91-484-4077907', email: 'ssacch@seahorsegroup.co.in' },
        { address: 'SEAHORSE SHIP AGENCIES PVT. LTD., COCHIN BRANCH\n\nADDRESS : PLOT NO. 10 A, NILHAT HOUSE, GROUND FLOOR, BRISTOW ROAD,\nWILLINGDON ISLAND, COCHIN 682 003, INDIA', rows: '4' },

        { area: 'HALDIA', cnt: '2', team: 'OFFICE', job: 'Overall', officeTel: '91-03-224252004', email: 'ssahal@seahorsegroup.co.in' },
        { address: 'SEAHORSE SHIP AGENCIES PVT. LTD., BANGALORE  BRANCH\n\nADDRESS : NO 302, RAHEJA CHAMBERS,12,MUSEUM ROAD,BANGALORE 560001', rows: '3' },

        { area: 'KOLKATA', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-33-40128540', email: 'abinashsubba@seahorsegroup.co.in' },
        { team: 'Document Team', job: 'Outbound Documentation', officeTel: '91-33-40128511', email: 'ssacal.expdoc@seahorsegroup.co.in' },
        { team: 'Document Team', job: 'Inbound Documentation', officeTel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
        { team: 'Customer Service', job: 'Customer Service', officeTel: '91-33-40128504', email: 'ssacal.mkt@seahorsegroup.co.in' },
        { address: 'SEAHORSE SHIP AGENCIES PVT. LTD., KOLKATA  BRANCH\n\nADDRESS: HASTINGS CHAMBERS, 4TH FLOOR,\n7C - KIRAN SHANKAR ROY ROAD, KOLKATA 700 001, INDIA', rows: '4' },

        { area: 'SEZ VIZAG RPCIPL', cnt: '3', team: 'Sales Team', job: 'Marketing & Sales & CS', officeTel: '91-891-2565775', email: 'hymavathi@seahorsegroup.co.in' },
        { team: 'Document Team', job: 'Documentation', officeTel: '91-891-2565775', email: 'ssaviz@seahorsegroup.co.in' },
        { address: 'SEAHORSE SHIP AGENCIES PVT. LTD., VIZAG BRANCH\n\nADDRESS : FLAT NO:211, 2ND FLOOR, VIP TOWERS, VIP ROAD, VISAKHAPATNAM 530 003, INDIA', rows: '3' },

        { area: 'TUTICORIN', cnt: '2', team: 'OFFICE', job: 'Overall', officeTel: '91-461-2352921', email: 'ssatut@seahorsegroup.co.in' },
        { address: 'SEAHORSE SHIP AGENCIES PVT. LTD., TUTICORIN BRANCH\n\nADDRESS : NO.180, NORTH COTTON ROAD,\nA.V.M.JEWELLERS THIRD FLOOR, TUTICORIN - 628001 INDIA', rows: '4' },

        { area: 'ICD HYDERABAD', cnt: '3', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '91-40-48559904 ', email: 'mvbprasad@seahorsegroup.co.in' },
        { team: 'Document Team', job: 'Documentation', officeTel: ' 91-40-48559904 ', email: 'mvbprasad@seahorsegroup.co.in' },
        { address: 'SEAHORSE SHIP AGENCIES PVT LTD. ICD HYDERABAD BRANCH\n\nADDRESS: 407, 4TH FLOOR, SWAPNALOK COMPLEX.,\n92 & 93, SD ROAD, SECUNDERABAD 500 003 TELANGANA, INDIA', rows: '4' }
      ]
    },
    {
      key: '13',
      keyNm: 'KH',
      info: [
        { area: 'SIHANOUKVILLE, PHNOM PENH', cnt: '5', team: 'Samedy Teng', job: 'Sales', officeTel: '(855)23 975914', email: 'salesmgr@meridian-asia.com' },
        { team: 'Polika Sim', job: 'Customer service', officeTel: '(855)23 975914', email: 'polika.sim@meridian-asia.com' },
        { team: 'Nimol Sok', job: 'Import document', officeTel: '(855)23 975914', email: 'nimol.sok@meridian-asia.com' },
        { team: 'Pisey Bou', job: 'Export document', officeTel: '(855)23 975914', email: 'pisey.bou@meridian-asia.com' },
        { address: 'MERIDIAN SHIPPING AGENCIES. (CAMBODIA) PTE LTD\n\nADDRESS: NO.269 CDEO, PLOV LOM, PHUM TERK THIA, SANGKAT TERK THIA, KHAN SEN SOK PHNOM PENH, CAMBODIA', rows: '3' }
      ]
    },
    {
      key: '14',
      keyNm: 'RU',
      info: [
        { area: 'VLADIVOSTOK', cnt: '2', team: 'OFFICE', job: 'Overall', officeTel: '7-423-2215700', email: 'mail@belsu.net' },
        { address: 'BELSU LOGISTICS CO., LTD. (VLADIVOSTOK/HEAD OFFICE)\n\nADDRESS : 1 FLOOR PALLADY STREET 1, VLADIVOSTOK, 690065, RUSSIA', rows: '3' }
      ]
    },
    {
      key: '15',
      keyNm: 'LK',
      info: [
        { area: 'COLOMBO', cnt: '7', team: 'Mr. Sylvester Jayasundera', job: 'Sales & Marketing', officeTel: '94-11-243-8951', email: 'sylvester.j@transmc.lk' },
        { team: 'Mr. Dhinuka Ahangamage', job: 'Sales & Marketing', officeTel: '94-11-243-8951', email: 'dhinuka.a@transmc.lk' },
        { team: 'Mr. Ranesh Cooray', job: 'Sales & Marketing', officeTel: '94-11-243-8952', email: 'ranesh.c@transmc.lk' },
        { team: 'Mr. Jude Nanditha', job: 'Documentation &  Customer Service', officeTel: '94-11-243-8953', email: 'jude@transmc.lk' },
        { team: 'Mr. Justin Laliston', job: 'Documentation &  Customer Service', officeTel: '94-11-243-8954', email: 'justin@transmc.lk' },
        { team: 'Mr. Niroshan Maduranga', job: 'Documentation &  Customer Service', officeTel: '94-11-243-8955', email: 'niroshan.m@transmc.lk' },
        { address: 'TRANSMARINE PRIVATE LTD.\n\nADDRESS: ABC COURT NO.117, HUNUPITIYA LAKE ROAD, COLOMBO 02, SRI LANKA.', rows: '3' }
      ]
    },
    {
      key: '16',
      keyNm: 'AE',
      info: [
        { area: 'JEBEL ALI', cnt: '5', team: 'Sales', job: 'Sales', officeTel: '971-4-359-4900', email: 'aesales@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Booking', officeTel: '971-4-359-4904', email: 'aemkt@ekmtc.com' },
        { team: 'Document Team', job: 'Documentation', officeTel: '971-4-359-4912', email: 'aedocs@ekmtc.com' },
        { team: 'Counter', job: 'D.O. COUNTER', officeTel: '971-4-352-3926', email: 'kmtcdoc@raishassansaadi.com' },
        { address: 'KMTC Middle East Shipping LLC\n\nPo Box 120735, Rais Hassan Saadi BLDG, Mankhool Road, Dubai, U.A.E.', rows: '3' }
      ]
    },
    {
      key: '17',
      keyNm: 'MM',
      info: [
        { area: 'YANGON', cnt: '6', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '(951) 8202057 / (951) 8202075 / (951) 8202070', email: 'wintwintkhine@starlineasia.com' },
        { team: 'Customer service Team', job: 'Booking', officeTel: '(951) 8202057 / (951) 8202075 / (951) 8202070', email: 'thandar.myint@meridian-asia.com' },
        { team: 'Customer service Team', job: 'Outbound Document', officeTel: '(951) 8202057 / (951) 8202075 / (951) 8202070', email: 'Foad@Jonoubdarya.com' },
        { team: 'Customer service Team', job: 'Inbound Document', officeTel: '(951) 8202057 / (951) 8202075 / (951) 8202070', email: 'ayechan@meridian-asia.com' },
        { team: 'Customer service Team', job: 'Inbound Document', officeTel: '(951) 8202057 / (951) 8202075 / (951) 8202070', email: 'mayzin.myint@meridian-asia.com' },
        { address: 'MERIDIAN SHIPPING MANAGEMENT SERVICES LTD. (MYANMAR)\n\nADDRESS: INTERNATIONAL COMMERCIAL CENTRE (ICC) 422/ 426 CORNER OF STRAND ROAD AND BOTAHTAUNG PAGODA ROAD, #06-01 BOTAHTAUNG TOWNSHIP, YANGON, MYANMAR.', rows: '4' }
      ]
    },
    {
      key: '18',
      keyNm: 'PK',
      info: [
        { area: 'KARACHI', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '(92) 21-35147910', email: 'export-kmtc.pk@umapk.com' },
        { team: 'Documentation Team', job: 'Outbound ', officeTel: '(92) 21-35825927', email: 'cs-kmtc.pk@umapk.com' },
        { team: 'Documentation Team', job: 'Inbound', officeTel: '(92) 21-35825799', email: 'csimp-kmtc.pk@umapk.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '(92) 21-35825937', email: 'cs-kmtc.pk@umapk.com' },
        { address: 'UNITED MARINE AGENCIES(PVT)LTD.\n\nADDRESS : 8TH FLOOR, BAHRIA COMPLEX - IV, MAIN CHAUDHRY KHALIQ-UZ-ZAMAN ROAD,\nGIZRI, CLIFTON, KARACHI - 75600, PAKISTAN.', rows: '4' }
      ]
    },
    {
      key: '19',
      keyNm: 'SA',
      info: [
        { area: 'DAMMAN', cnt: '3', team: 'Sales Team', job: 'Sales', officeTel: '966-13-8059995', telRow: '2', email: 'moazkarrar@trident-freight.com' },
        { team: 'Documentation Team', job: 'Documentation', officeTel: '966-13-8059995', email: 'alsalbukh.h@trident-freight.com' },
        { address: 'TRIDENT FREIGHT SAUDI LLC\n\nADDRESS: ALASHRIAH STREET, CORNICHE ROAD, GLOBE BUILDING,, 4TH FLOOR OFFICE # 11 , DAMMAM - SAUDI ARABIA', rows: '3' },

        { area: 'RIYADH', cnt: '3', team: 'Sales Team', job: 'Sales', officeTel: '966-13-8059995', email: 'moazkarrar@trident-freight.com' },
        { team: 'Documentation Team', job: 'Documentation', officeTel: '966-13-8059995', email: 'alsalbukh.h@trident-freight.com' },
        { address: 'TRIDENT FREIGHT SAUDI LLC\n\nADDRESS: ALASHRIAH STREET, CORNICHE ROAD, GLOBE BUILDING,, 4TH FLOOR OFFICE # 11 , DAMMAM - SAUDI ARABIA', rows: '3' },

        { area: 'JEDDAH - KSA\n(WESTERN REGION)', cnt: '5', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '+966 12 6040245-6', email: 'mohsin@trident-freight.com' },
        { team: 'Export Documentation team', job: 'Outbound Export Documentation', officeTel: '+966 12 6040245-6', email: 'expjed@trident-freight.com' },
        { team: 'Import Documentation team', job: 'Inbound Import Documentation', officeTel: '+966 12 6040245-6', email: 'impjed@trident-freight.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '+966 12 6040245-6', email: 'csjed@trident-freight.com' },
        { address: 'TRIDENT FREIGHT SAUDI LLC\n\nADDRESS: 205/505, Al Ashore Building, P.O. Box 8852, Ibrahim Shaker Street, Al-Baghdadiya District, Al-Gharbiya, Jeddah 21492, Saudi Arabia.', rows: '3' }
      ]
    },
    {
      key: '20',
      keyNm: 'OM',
      info: [
        { area: 'SOHAR', cnt: '4', team: 'Sales Team', job: 'Sales', officeTel: '968 24790992 / 968 26752509 ', email: 'kmtcoman@ekmtc.com' },
        { team: 'Customer Service Team', job: 'Outbound', officeTel: '968 24790992', email: 'doc@sealandoman.om' },
        { team: 'Customer Service Team', job: 'Inbound', officeTel: '968 24790992 / 968 26752509', email: 'Import.kmtc@sealandoman.com' },
        { address: 'SEA LAND SHIPPING AND LOGISTICS SERVICES LLC\n\nADDRESS : 1 ST FLOOR, AL SHUMOOR BUILDING, OPP. OMAN CHAMBER OF COMMERCE & OMAN ARAB BANK\nP.C. : 114, P.O. BOX : 1959, CBD AREA, MUSCAT , SULTANATE OF OMAN', rows: '4' }
      ]
    },
    {
      key: '21',
      keyNm: 'KE',
      info: [
        { area: 'MOMBASA', cnt: '4', team: 'Sales Team', job: 'Sales', officeTel: '+ 254-721144576', email: 'mkt@rsske.com' },
        { team: 'Customer Service Team', job: 'Customer Service', officeTel: '+ 254-729403439', email: 'cs@rsske.com' },
        { team: 'Documentation Team', job: 'Documentation', officeTel: '+ 254-2134100569', email: 'docs1@rsske.com' },
        { address: 'RAIS SHIPPING SERVICES (KENYA) LTD\n\nADDRESS: MIKANJUNI ROAD, OFF MOI AVENUE, P.O. BOX 87684-80100, MOMBASA, KENYA', rows: '3' }
      ]
    },
    {
      key: '22',
      keyNm: 'IR',
      info: [
        { area: 'BASRAH', cnt: '8', team: 'Capt. Maan', job: 'General Manager', officeTel: '964 770-492-5959', email: 'captmaan@simamarineiraq.com' },
        { team: 'Capt. Muntaser', job: 'Sales Manager', officeTel: '964 770-492-5959', email: 'muntaser@simamarineiraq.com' },
        { team: 'Mr. Bharat', job: 'Line Manager', officeTel: '964 770-493-9703', email: 'ops3@simamarineiraq.com' },
        { team: 'Mr. Srikanth', job: 'Accounts Manager', officeTel: '964 772-989-4137', email: 'accts@simamarineiraq.com' },
        { team: 'Mr. Sarfraz', job: 'Operations Sr Executive', officeTel: '964 772-999-2675', email: 'sarfraz@simamarineiraq.com' },
        { team: 'Ms. Tabarek', job: 'Operations Executive', officeTel: '-', email: 'ops2@simamarineiraq.com' },
        { team: 'Mr. Ameer', job: 'Documetation & Customer Service', officeTel: '964 770-317-3184', email: 'hala@simamarineiraq.com' },
        { address: 'Sima Marine (Iraq)\n\nADDRESS: Office NO. : 148/23, Lane : 27, Quarter : 305, AL JAZAEAR - SANA\'A DISTRICT, BASRAH, IRAQ', rows: '3' }
      ]
    },
    {
      key: '23',
      keyNm: 'KU',
      info: [
        { area: 'KUWAIT', cnt: '3', team: 'Operations & Docs', job: 'Operations & Documentations', officeTel: 'TEL : +965 22451325 / MOB: +965 69681953', email: 'opskwt@celerityship.com' },
        { team: 'Management', job: 'Management', officeTel: 'TEL : +965 22451325 / MOB: +965 97226912', email: 'sean@celerityship.com' },
        { address: 'Celerity Shipping & Forwarding Co. W.L.L. (Kuwait)\n\nADDRESS: SUHAIR COMMERCIAL CENTER, 5TH FLOOR, AL HILALI STREET, BESIDE KIPCO TOWER. SHARQ, KUWAIT', rows: '3' }
      ]
    },
    {
      key: '24',
      keyNm: 'TZ',
      info: [
        { area: 'TANZANIA', cnt: '6', team: 'Accounts / Mr. Ganesan Pitchumani', job: 'Accounts Manager', officeTel: '255 688 945 158', email: 'ganesan.p@rsstz.com' },
        { team: 'Accounts / Ms. Donattela Kavishe', job: 'Accounts Executive', officeTel: '255 716 731 658', email: 'dar.accounts1@rsstz.com' },
        { team: 'Export Documentation / Mr. Nassim Ally Khatib', job: 'Export Documentation Executive', officeTel: '255 625 504 125', email: 'dar.exp1@rsstz.com' },
        { team: 'Import Documentiaion / Mr. Fredrick Wilfred Miyangaro', job: 'Import Documentation Executive', officeTel: '255 689 501 231', email: 'dar.imports@rsstz.com' },
        { team: 'Import Documentiaion / Proaches Patrick Kimaro', job: 'Import Documentation Executive', officeTel: '255 718 814 997', email: 'dar.imports1@rsstz.com' },
        { address: 'RAIS SHIPPING SERVICES (TANZANIA) LIMITED\n\nADDRESS: P.O. BOX 79869, HARBOUR VIEW TOWERS ROOM NO. 224, 2ND FLOOR DAR ES SALAAM, TANZANIA\nTelephone Number : +255 22 2136860 / 61 , Fax Number : +255 22 213868', rows: '4' }
      ]
    },
    {
      key: '25',
      keyNm: 'EG',
      info: [
        { area: 'SOKHNA PORT', cnt: '6', team: 'Sales Team', job: 'Sales & Marketing', officeTel: '20-3-3994855', email: 'saleslnr@latt.com.eg' },
        { team: 'Document Team', job: 'Outbound Document', officeTel: '20-3-3994843', email: 'expdclnr@latt.com.eg' },
        { team: 'Document Team', job: 'Inbound Document', officeTel: '20-3-3994840', email: 'impdclnr@latt.com.eg' },
        { team: 'Customer Service Team', job: 'Outbound Customer Service', officeTel: '20-3-3993817', email: 'expcslnr@latt.com.eg' },
        { team: 'Customer Service Team', job: 'Inbound Customer Service', officeTel: '20-3-3994811', email: 'impcslnr@latt.com.eg' },
        { address: 'LATT Trading & Shipping Co.\n\nADDRESS: B 30 Lomomba Str, Elshalalat, ALEXANDRIA, EGYPT.', rows: '3' }
      ]
    },
    {
      key: '26',
      keyNm: 'JO',
      info: [
        { area: 'JO-AQJ', cnt: '8', team: 'Sales Team', job: 'Inbound Sales', officeTel: '+962-6-500-4446', email: 'Kmtc-sales@naouri.com' },
        { team: 'Export Team', job: 'Outbound Sales & Documentation', officeTel: '+962-6-500-4446', email: 'Kmtc-exp@naouri.com' },
        { team: 'Customer Service Team', job: 'Inbound Documentation', officeTel: '+962-6-500-4446', email: 'Kmtc-doc@naouri.com' },
        { address: 'Salam Shipping and Forwarding Agency\n\nADDRESS: 30, Saad Bin Abi Waqqas Street, Amman, Jordan', rows: '3' }
      ]
    }
  ]
}
