<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU09.141') }}</h1>
    <table width="100%" cellpadding="0" cellspacing="0" align=center  class="tbl_col">
        <tr height=15>
            <td colspan="4" style="border-left-width:0px;border-right-width:0px;text-align:left">
                <p align="left"><b><font face="맑은고딕" size="4" color="#004080">보내고자 하는 카드를 선택하세요.</font></b></p>
            </td>
       </tr>
        <tr valign="middle">
            <td width="50%" align="center" style="border-bottom-width:0px;"><img src="https://www.ekmtc.com/img/kmtc2025_001.jpg" width="500" height="350"/></td>
            <td width="50%" align="center" style="border-bottom-width:0px;"><img src="https://www.ekmtc.com/img/kmtc2025_002.jpg" width="500" height="350"/></td>
        </tr>
        <tr height=25>
           <td align="center">
               <font face="맑은고딕" size="3" color="#282828"><b><input id="pic1" type="radio" name="R1" value="kmtc2025_001" checked v-model="cardCd" />
                  <label for="pic1">카드1</label></b>
               </font>
            </td>
           <td align="center">
                <font face="맑은고딕" size="3" color="#282828"><b><input id="pic2" type="radio" name="R1" value="kmtc2025_002" v-model="cardCd"/>
                   <label for="pic2">카드2</label></b>
                </font>
           </td>
        </tr>
    </table>
    <div class="mt10 flex_box">
       <span class="ml_auto">
           <a class="button blue lg ml5" @click="goNextStep()">다음</a>
       </span>
    </div>
  </div>
</template>
<script>
import { rootComputed, rootMethods } from '@/store/helpers'
import commons from '@/api/services/commons'
export default {
    name: 'sendCard',
    data () {
        return {
            cardCd: 'kmtc2024_001'
        }
    },
    computed: {
    ...rootComputed
    },
    methods: {
        goNextStep () {
            this.$router.push({ name: 'sendNewYearCardNextStep', params: { cardCd: this.cardCd } }).catch(() => {})
        },
        goPrecedingStep () {
            this.$router.go(-1)
        }
    }
}
</script>
